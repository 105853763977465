import React from 'react'
import styled from 'styled-components'

const MonotonDropShadowLogo = styled.div`
  font-family: 'Monoton';
  font-weight: 400;
  font-style: normal;
  font-size: 4em;
  color: #346;
`

const SubLogo = styled.div`
  font-size: 1em;
  color: #fff;
`

const Logo = () => (
  <div>
    <MonotonDropShadowLogo>AKFX</MonotonDropShadowLogo>
    <SubLogo>Adam&nbsp;Keenan&nbsp;Animatronics</SubLogo>
  </div>
)

export default Logo
